import React from 'react';
import { StyledCard, StyledCardContent, StyledTypographyTitle, StyledTypographyContent } from '../styles/ArticleStyles';

const Article: React.FC<{ title: string; content: string; }> = ({ title, content }) => {
  return (
    <StyledCard>
      <StyledCardContent>
        <StyledTypographyTitle variant="h5" component="div">
          {title}
        </StyledTypographyTitle>
        <StyledTypographyContent variant="body2" color="textSecondary">
          {content}
        </StyledTypographyContent>
      </StyledCardContent>
    </StyledCard>
  );
}

export default Article;
