import { styled } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';

export const StyledAppBar = styled(AppBar)`
   background-color:#d1ecf4;
  box-shadow: none;
`;

export const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: space-between;
`;

export const StyledLogoContainer = styled('div')`
  display: flex;
  align-items: center;
`;

export const StyledLogo = styled('img')`
  height: 40px;
  margin-right: 8px;
`;

export const StyledButton = styled(Button)`
  background-color: #d1ecf4;
  color: #000;
  &:hover {
    background-color: #1b75ce;
  }
`;

export const StyledMenuIconButton = styled(IconButton)`
  margin-left: 16px;
`;
