import styled from 'styled-components';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

export const StyledContainer = styled(Container)`
  
  background-color: #15579c;
    height: 100%;
    position: relative;
    justify-content: center;
    align-items: center;
    display: grid;
`;

export const StyledGrid = styled(Grid)`

  
 
`;
