import styled from 'styled-components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StyledFooter = styled(Box)`
   height: 20vh;
  text-align: center;
  background-color: #f8f9fa;
`;

export const StyledTypography = styled(Typography)`
  color: #666;
`;
