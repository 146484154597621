import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

export const StyledCard = styled(Card)`
  margin: 16px 0;
`;

export const StyledCardContent = styled(CardContent)``;

// Ensure that the styled component retains the original Typography props
export const StyledTypographyTitle = styled(Typography)<{ component: string }>`
  font-size: 1.5rem;
  font-weight: bold;
`;

export const StyledTypographyContent = styled(Typography)`
  color: #666;
`;
