import React from 'react';
import logo from '../assets/main-fusion-logo.png'; // Make sure you have the logo image in the assets folder

import { StyledHeroContainer, StyledHeroTitle, StyledHeroSubtitle, StyledLogo } from '../styles/HeroStyles';

const Hero: React.FC = () => {
  return (
    <StyledHeroContainer>
      <StyledHeroTitle>Welcome to Fusion</StyledHeroTitle>
       <StyledLogo src={logo} alt="Fusion Logo" />
      <StyledHeroSubtitle>Your secure crypto wallet built on the SUI Network</StyledHeroSubtitle>
    </StyledHeroContainer>
  );
}

export default Hero;
