import React from 'react';
import { StyledAppBar, StyledToolbar, StyledLogoContainer, StyledLogo, StyledButton, StyledMenuIconButton } from '../styles/NavbarStyles';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logo from '../assets/main-fusion-logo.png'; // Make sure you have the logo image in the assets folder

const Navbar: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <StyledAppBar position="static">
      <StyledToolbar>
        <StyledLogoContainer>
          <StyledLogo src={logo} alt="Fusion Logo" />
          <Typography variant="h6">Fusion</Typography>
        </StyledLogoContainer>
        {!isMobile && (
          <StyledButton variant="contained">Download</StyledButton>
        )}
        <StyledMenuIconButton edge="end" color="inherit" aria-label="menu">
          <MenuIcon />
        </StyledMenuIconButton>
      </StyledToolbar>
    </StyledAppBar>
  );
}

export default Navbar;
