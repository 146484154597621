import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const StyledHeroContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 55vh;
  width: 100%;
  background-color:#d1ecf4;
  text-align: center; 
  /* background-color: beige; */
  padding-top: 2rem;
  margin-top: 2%;

  @media (max-width: 768px) {
   
    margin-top: 5%; 
  }
`;


export const StyledHeroTitle = styled(Typography)`
  font-size: 2rem;
  font-weight: bold;
  color: #333;

`;
export const StyledHeroSubtitle = styled(Typography)`
  font-size: 1.25rem;
  color: #666;
`;
export const StyledLogo = styled('img')`
  height: 220px;
  margin-right: 8px;
`;