import React from 'react';
import { StyledContainer, StyledGrid } from '../styles/HomeStyles';
import Article from '../components/Article';
import Hero from '../components/Hero';

const Home: React.FC = () => {
  return (
    <StyledContainer>
      
      <StyledGrid container spacing={0}>
        <StyledGrid item xs={12} md={12}>
          <Hero />
        </StyledGrid>
      </StyledGrid>

      <StyledGrid container spacing={3}>
        <StyledGrid item xs={12} md={4}>
          <Article title="Article 1" content="Content for article 1." />
        </StyledGrid>
        <StyledGrid item xs={12} md={4}>
          <Article title="Article 2" content="Content for article 2." />
        </StyledGrid>
        <StyledGrid item xs={12} md={4}>
          <Article title="Article 3" content="Content for article 3." />
        </StyledGrid>
      </StyledGrid>
    </StyledContainer>
  );
}

export default Home;
