import React from 'react';
import { StyledFooter, StyledTypography } from '../styles/FooterStyles';

const Footer: React.FC = () => {
  return (
    <StyledFooter component="footer">
      <StyledTypography variant="body2">
        © Fusion 2024
      </StyledTypography>
    </StyledFooter>
  );
}

export default Footer;
